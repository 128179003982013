<template>
  <b-row>
    <b-col>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1 mt-5"
      >
        <b-spinner label="Loading..." />
      </div>
      <new-table
        v-else
        :export-file-name="exportFileName"
        table-name="rides-list"
        :columns="columns"
        :items="items"
        router-link="view-ride"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  VBTooltip, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import Moment from 'moment'
import NewTable from '@/views/new-table.vue'

export default {
  components: {
    BSpinner,
    BCol,
    BRow,
    NewTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      moment: Moment,
      loading: false,
      exportFileName: `ridesList_${Moment(new Date()).format('YYYY-MM-DD')}`,
      oldFilter: '',
      items: [],
      columns: [
        {
          label: 'Start time',
          data: 'start_time',
          type: 'date',
          format: 'dd-MM-yyyy hh:mm a',
          calculateCellValue(data) {
            return Moment.utc(data.start_time).local().format('yyyy-MM-D hh:mm:ss a')
          },
        },
        {
          label: 'End Time',
          data: 'end_time',
          type: 'date',
          format: 'dd-MM-yyyy hh:mm a',
          calculateCellValue(data) {
            return Moment.utc(data.end_time).local().format('yyyy-MM-D hh:mm:ss a')
          },
        },
        {
          label: 'Scooter Serial',
          data: 'scooter_serial',
        },
        {
          label: 'Customer',
          data: 'customer_email',
        },
        {
          label: 'Distance',
          data: 'distance',
        },
        {
          label: 'Amps',
          data: 'points',
        },
        {
          label: 'Controller Temperature',
          data: 'controller_temp',
        },
        {
          label: 'Device',
          data: 'device',
          custom_template: {
            template_name: 'rides.device_template',
            options: {
              android: {
                icon: 'android-icon',
                tooltip: 'Android',
              },
              ios: {
                icon: 'ios-icon',
                tooltip: 'IOS',
              },
              react: {
                icon: 'react-icon',
                tooltip: 'React',
              },
            },
          },
          calculateCellValue(data) {
            return data.device == undefined ? 'react' : data.device.toLowerCase()
          },
        },
      ],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load(filter = null) {
      this.loading = true
      this.$http.get(this.$resources.customers.rides.replace(':id', this.$route.params.id)).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.oldFilter = filter
          this.items = data.rows
          this.loading = false
        }
      })
    },
    view(item) {
      this.$router.push(`/rides/${item.id}`)
    },
  },
}
</script>

<style>
.scooter-icon {
  background-image: url('~@/assets/images/icons/ride/scooter.png');
}

.ios-icon {
  background-image: url('~@/assets/images/icons/ride/apple.png');
}

.android-icon {
  background-image: url('~@/assets/images/icons/ride/android.png');
}

.react-icon {
  background-image: url('~@/assets/images/icons/ride/react.png');
}

.unknown-icon {
  background-image: url('~@/assets/images/icons/ride/unknown.png');
}
</style>
