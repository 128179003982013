<template>
  <b-card class="h-100">
    <div class="heading">
      Connect+
    </div>
    <div class="spinner-container">
      <b-spinner
        v-if="isLoading"
        label="Loading..."
      />
      <div v-else-if="subscriptions.length === 0">
        No subscription
      </div>
    </div>

    <b-tabs nav-wrapper-class="subscriptions-tabs">
      <b-tab
        v-for="subscription in subscriptions"
        :key="subscription.serial"
        :title="subscription.serial"
      >
        <b-row cols="12">
          <b-col
            cols="8"
            class="col-header"
          >
            General info
          </b-col>
          <b-col
            cols="4"
            class="col-header"
          >
            Payment info
          </b-col>
        </b-row>
        <b-row class="menus-wrapper">
          <ul class="connect-plus-menu list-unstyled col cols-6">
            <li class="row item-wrapper">
              <b-col cols="6">
                <span class="label">Joined</span>
              </b-col>
              <b-col><span class="data">{{ subscription.joind }}</span></b-col>
            </li>
            <li class="row item-wrapper">
              <b-col cols="6">
                <span class="label">Connect+ status</span>
              </b-col>
              <b-col>
                <span
                  class="data status badge "
                  :class="subscription.isActive ? 'badge-light-success' : 'badge-light-danger'"
                >{{ subscription.status }}</span>
              </b-col>
            </li>
            <li class="row item-wrapper">
              <b-col cols="6">
                <span class="label">Billing cycle</span>
              </b-col><b-col><span class="data">{{ subscription.package }}</span></b-col>
            </li>
          </ul>
          <ul class="connect-plus-menu list-unstyled col cols-6">
            <li class="row item-wrapper">
              <b-col cols="6">
                <span class="label">Sim Card status</span>
              </b-col>
              <b-col>
                <span
                  class="data status badge "
                  :class="subscription.simCardIsActive ? 'badge-light-success' : 'badge-light-danger'"
                >{{ subscription.simCardStatus }}</span>
              </b-col>
            </li>
            <li class="row item-wrapper">
              <b-col cols="6">
                <span class="label">Data usage</span>
              </b-col>
              <b-col><span class="data">{{ subscription.dataPercent }}% - {{ subscription.dataUsage }}mb / {{ subscription.dataLimit }}mb</span></b-col>
            </li>
            <li class="row item-wrapper">
              <b-col cols="6">
                <span class="label">Next charge</span>
              </b-col>
              <b-col>
                <date-picker
                  id="nextPaymentPicker"
                  v-model="subscription.nextPaymentDate"
                  type="date"
                  name="nextPaymentDate"
                  :confirm="true"
                  :clearable="false"
                  @change="setNextPaymentDate(subscription)"
                />
              </b-col>
            </li>
          </ul>
          <ul
            class="connect-plus-menu list-unstyled col cols-6"
          >
            <li
              v-for="payment in subscription.payments"
              :key="payment.date"
              class="row item-wrapper"
            >
              <b-col>
                <span class="label">{{ payment.date }}</span>
              </b-col>
              <b-col><span class="data">{{ payment.amount }}$</span></b-col>
            </li>
          </ul>
        </b-row>
        <div
          class="btn-black"
          @click="disable_sim(subscription)"
        >
          {{ subscription.simCardIsActive ? 'Disable Sim' : 'Enable sim' }}
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { BTabs } from 'bootstrap-vue'
import { getSimCardStatusName, getSubscriptionStatusName } from '@core/utils/subscriptions'
import Moment from 'moment'
import DatePicker from 'vue2-datepicker'

export default {
  components: { DatePicker, BTabs },
  props: {
    customerId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      moment: Moment,
      isLoading: true,
      subscriptions: [],
    }
  },
  mounted() {
    this.loadConnectPlusData()
  },
  methods: {
    loadConnectPlusData() {
      this.isLoading = true
      this.$http.get(this.$resources.customers.connectPlus.replace(':id', this.customerId)).then(response => {
        this.isLoading = false
        const { data: { status, data } } = response
        if (status === 200) {
          this.subscriptions = data.rows.map(row => {
            const bundles = row.sim_card?.bundles
            const bundle = bundles?.length ? bundles[0] : undefined
            const nextPaymentDate = row.subscription.next_payment ? this.moment.utc(row.subscription.next_payment * 1000).toDate() : '-'
            return {
              id: row.subscription.id,
              serial: row.serial,
              simCard: row.sim_card,
              joind: this.moment.utc(row.subscription.created_at * 1000).format('DD MMM YYYY'),
              isActive: row.subscription.status === 21006 || row.subscription.status === 21009,
              status: getSubscriptionStatusName(row.subscription.status),
              package: row.subscription.package,
              simCardStatus: getSimCardStatusName(row.sim_card.status),
              simCardIsActive: row.sim_card.status < 40,
              dataUsage: Math.round(((bundle?.current_cycle_usage ?? 0) / 1024 / 1024) * 100) / 100,
              dataLimit: Math.round(((bundle?.data_limit ?? 0) / 1024 / 1024) * 100) / 100,
              dataPercent: Math.round(((bundle?.current_cycle_usage ?? 0) / (bundle?.data_limit ?? 1)) * 100),
              payments: row.payment.filter(payment => payment.date !== null).map(payment => ({
                amount: payment.amount,
                date: this.moment.utc(payment.date * 1000).local().format('DD MMM YYYY'),
              })),
              nextPaymentDate,
              savedNextPaymentDate: nextPaymentDate,
            }
          })
        }
      })
    },
    disable_sim(subscription) {
      this.$swal({
        title: subscription.simCardIsActive ? 'Disable sim card' : 'Enable sim card',
        text: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.value) {
          this.loading = true
          this.$http.post(subscription.simCardIsActive
            ? this.$resources.iot.disableSimCard
            : this.$resources.iot.enableSimCard,
          { serial: subscription.serial }).then(() => {
            this.loading = false
            this.loadConnectPlusData()
          })
        }
      })
    },
    setNextPaymentDate(subscription) {
      this.$swal({
        title: 'Next Charge Date',
        text: `Are you sure that you want to set the next payment date to ${this.moment(subscription.nextPaymentDate).format('DD MMM YYYY')}`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(async result => {
        if (result.value) {
          this.$http.post(this.$resources.customers.connectPlusMovePayment
            .replace(':id', this.customerId)
            .replace(':sid', subscription.id),
          { date: this.moment(subscription.nextPaymentDate).format('Y-MM-DD') }).then(() => {
            subscription.savedNextPaymentDate = subscription.nextPaymentDate
          }).catch(() => {
            subscription.nextPaymentDate = subscription.savedNextPaymentDate
          })
        } else {
          subscription.nextPaymentDate = subscription.savedNextPaymentDate
        }
      })
    },
  },
}
</script>

<style lang="scss">

    .heading {
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0;
        color: #151210;
    }
    .menus-wrapper {
        margin-top: 10px;
        .connect-plus-menu{
            .item-wrapper {
                margin-top: 5px;
                .label {
                    color: #A19590;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 0;
                    text-align: left;
                }
                .data {
                    &:not(.status){
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 22px;
                        letter-spacing: 0;
                        text-align: left;
                        color: #151210;
                    }
                }
            }
        }
    }
    .btn-black {
        transition: all .1s;
        cursor: pointer;
        box-shadow: none;
        border-radius: 10px !important;
        width: fit-content;
        padding: 10px 25px;
        background-color: #151210;
        line-height: 18px;
        color:#FCFBFB;
        &:hover {
            background-color: #fff;
            color: #151210;
            outline: 1px solid #151210;
        }
    }

    .subscriptions-tabs {
        margin-top: 16px;
        .nav-link {
            padding: 2px !important;
        }
        .nav-item {
          flex-grow: 1;
        }
    }

    .col-header {
      font-weight: bold;
    }

    .edit-next-payment-link {
      text-decoration: underline;
    }
</style>
