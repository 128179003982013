<template>
  <b-row>
    <b-col>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1 mt-5"
      >
        <b-spinner label="Loading..." />
      </div>
      <!-- <new-table v-else cutomFunction="getUrl" :exportFileName="exportFileName" :customLink="true"  tableName="users-tickets-list" :columns="columns" :items="items" /> -->
      <b-table
      :items="items"
      responsive
      :fields="fields"
      class="mb-0 tickets-card"
    >
      <template #cell(actions)="data">
        <center>
          <feather-icon
            v-b-tooltip="'View'"
            icon="SearchIcon"
            class="mr-50"
            @click="openLink(data.item.link)"
          />
        </center>
      </template>
    </b-table>
    </b-col>
  </b-row>
</template>

<script>
import {
  VBTooltip, BCol, BRow, BSpinner,BButton
} from 'bootstrap-vue'
import Moment from 'moment'
import NewTable from '@/views/new-table.vue'

export default {
  components: {
    BSpinner,
    BCol,
    BRow,
    BButton,
    NewTable
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      moment: Moment,
      loading: false,
      exportFileName: "TicketsList_"+Moment(new Date()).format('YYYY-MM-DD'),
      oldFilter: '',
      items: [],
      fields: [
        {
          label: 'Date',
          key: 'date',
        },
        {
          label: 'Status',
          key: 'status',
        },
        {
          label: 'Action',
          key: 'actions',
        },
      ],
      
      columns: [   
          {
            label: 'Date',
            data: 'date',
          },

          {
            label: 'Status',
            data: 'status',
          },
          {
            label: 'Action',
            data: 'link',
          },
      ]
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    openLink(link) {
      open(link,'_blank');
    },
    load() {
      this.loading = true;
      this.$http.get(this.$resources.customers.tickets.replace(':id', this.$route.params.id)).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.items = data.tickets.map(t => ({ ...t, date: Moment(t.date).format('ll') }))
          this.loading = false
        }
      });
  }
}
}
</script>

<style>

</style>
