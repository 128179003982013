<template>
  <div class="customer-statistics h-100">
    <b-card class="h-100">

      <b-row class="heading">
        <span
          class="title"
        >
          Activities of selected period
        </span>
      </b-row>
      <b-row
        class="datetimepicker-wrapper"
      >
        <date-picker
          id="month"
          v-model="selectedDate"
          type="datetime"
          range
          name="datepicker"
          :confirm="true"
          :disabled-date="disabledDates"
          @change="updateStatus(selectedDate)"
        />
      </b-row>

      <b-row
        v-if="loading"
        class="pt-2"
      >
        <b-col class="d-flex justify-content-center">
          <b-spinner label="Loading..." />
        </b-col>
      </b-row>
      <b-row
        v-else
        class="data-list"
      >
        <b-col
          v-for="(customerStatistic, key) in customerStatistics"
          :key="key"
          class="data-list-col"
        >
          <span
            class="icon time-icon"
            :class="`${customerStatistic.icon}-icon`"
          />
          <div class="data-wrapper">
            <div class="label">
              {{ customerStatistic.label }}
            </div>
            <div
              v-if="key !== 'time'"
              class="data"
            >
              {{ customerStatistic.data ? parseInt(customerStatistic.data).toFixed(2) : 0 }}{{ customerStatistic.unit }}
            </div>
            <div
              v-else
              class="data"
            >
              {{ customerStatistic.data ? getTimeStr(customerStatistic.data): 0 }}
            </div>
          </div>
        </b-col>

      </b-row>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

momentDurationFormatSetup(moment)

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      loading: false,
      selectedDate: null,
      dateFormat: 'DD-MM-YYYY HH:mm:ss',
      pickerDateFormat: 'DD-MM-YYYY',
      pickerTimeFormat: 'HH:mm:ss',
      customerStatistics: {
        time: {
          label: 'Rides time',
          data: '-',
          icon: 'time',
        },
        rides: {
          label: 'Rides',
          data: '-',
          icon: 'rides',
        },
        distance: {
          label: 'Distance',
          data: '-',
          icon: 'distance',
          unit: 'km',
        },
        tripLength: {
          label: 'Average trip length',
          data: '-',
          icon: 'trip-length',
          unit: 'km',
        },
      },
    }
  },
  computed: {
    maxDate() {
      const currentDate = new Date()
      return new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
      )
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    disabledDates(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date > today
    },
    getTimeStr(seconds) {
      const duration = moment.duration(seconds, 'seconds')
      return duration.format('d[d] h[h] m[m]')
    },
    updateStatus(modelData) {
      this.selectedDatesRange = {
        from_date: modelData[0] ? `${moment.utc(modelData[0]).format(this.dateFormat)}` : '',
        to_date: modelData[1] ? `${moment.utc(modelData[1]).format(this.dateFormat)}` : '',
      }
      if (this.selectedDatesRange.from_date && this.selectedDatesRange.to_date) {
        this.load(this.selectedDatesRange)
      } else {
        this.load()
      }
    },

    load(date = null) {
      this.loading = true
      this.$http.get(this.$resources.customers.achievements.replace(':id', this.$route.params.id), { params: date }).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.customerStatistics.time.data = data.rides_time
          this.customerStatistics.rides.data = data.count
          this.customerStatistics.distance.data = data.distance
          this.customerStatistics.tripLength.data = data.avg_trip_length
          this.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
.customer-statistics {
  .heading {
    align-items: center;
    .title {
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #151210;
    }
    .v-calendar {
      float: right;
      .input-field {
        input {
          height: 35px;
          width: 100%;
          height: 24px;
          border-radius: 5px;
          border: 1px solid #eaeaeb;
          padding: 20px;
          padding-left: 40px;
          padding-right: 20px;
          gap: 8px;
          border-radius: 15px;

        }
      }
    }
  }
  .v-calendar {
    .content {
      display: flex;
      flex-direction: column;
    }
  }
  .datetimepicker-wrapper {
    .mx-datepicker-range {
      width: 100%;
    }
    .mx-datepicker-popup {
      .mx-range-wrapper {
        flex-direction: column !important;
      }
    }
  }

  .data-list {
      margin-top: 10px;
        .data-list-col {
            display: flex;
            .icon{
              display: block;
              width: 40px;
              height: 40px;
              padding: 8px;
              border-radius: 8px;
              border: 1px;
              &.time-icon {
                background-image: url('~@/assets/images/icons/customer/time.png') !important;
              }
              &.rides-icon {
                background-image: url('~@/assets/images/icons/customer/rides.png') !important;
              }
              &.distance-icon {
                background-image: url('~@/assets/images/icons/customer/distance.png') !important;
              }
              &.trip-length-icon {
                background-image: url('~@/assets/images/icons/customer/trip-length.png') !important;
              }
            }
            .data-wrapper {
                margin-left: 10px;
                .label {
                    font-size: 12px;
                    font-weight: 500;
                    color: #9A8781;
                }
                .data {
                    font-size: 18px;
                    font-weight: 600;
                    color: #151210;
                }

            }
        }

}

// media queries
@media screen and (max-width: 767px) {
    .customer-statistics {
      margin-top:10px;
      .heading {
          .title {
            margin-bottom: 10px !important;
          }
      }
    }
 }

}

</style>
