export const getSubscriptionStatusName = status => {
  switch (status) {
    case 21001:
      return 'Scooter is not connected'
    case 21002:
      return 'Eligible for free trial'
    case 21003:
      return 'Not eligible for free trial'
    case 21006:
      return 'Subscribed'
    case 21007:
      return 'Suspended'
    case 21008:
      return 'Unsubscribed'
    case 21009:
      return 'Cancellation Requested'
    default:
      return 'Unknown'
  }
}

export const getSimCardStatusName = status => {
  switch (status) {
    case 10:
      return 'silent period'
    case 23:
      return 'usage period'
    case 30:
      return 'suspension period'
    case 40:
      return 'shutdown reserved number'
    case 50:
      return 'abandonment period'
    default:
      return 'unknown'
  }
}

