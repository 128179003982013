<template>
  <div v-if="isFetchingCustomerId"></div>
  <div v-else-if="customerNotFound">
    Customer not found
  </div>
  <div
    v-else
    class="customer-single-page"
  >
    <div class="page-title-wrapper mb-1">
      <paginator
        target="customers"
        :navigation-data="paginator"
        :updated="navigationUpdated"
      >
        Customer
      </paginator>
    </div>

    <user-card
      ref="customerCard"
      :key="`customer-${$route.params.id}`"
      autoload
      :customer-i-d="$route.params.id"
    />

    <b-row class="pb-1">
      <b-col
        cols="12"
        sm="12"
        lg="8"
        class="customer-placeholder"
      >
        <connect-plus :customer-id="this.$route.params.id" />
      </b-col>
      <b-col
        cols="12"
        sm="12"
        lg="4"
        class="customer-statistics-wrapper"
      >
        <customer-statistics
          ref="customerStatistics"
          :key="`customer-statistics-${$route.params.id}`"
        />
      </b-col>
    </b-row>

    <b-card class="customer-data">
      <b-tabs>

        <b-tab>
          <template #title>
            <feather-icon icon="MapIcon" />
            <span>Rides</span>
          </template>
          <rides-table
            ref="customerRidesTable"
            :key="`customer-rides-table-${$route.params.id}`"
          />
        </b-tab>

        <b-tab>
          <template #title>
            <img
              src="@/assets/images/icons/customer/scooter-tab-icon.png"
              style="width: 20px;margin-right: 7px"
              alt=""
            >
            <span>Scooters</span>
          </template>
          <scooters-table
            ref="customerScootersTable"
            :key="`customer-scooters-table-${$route.params.id}`"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <img
              src="@/assets/images/logo/gorgias.png"
              style="width: 100px;margin-right: 2px"
              alt=""
            >
            <!-- <span>Tickets</span> -->
          </template>
          <tickets-table
            ref="customerTicketsTable"
            :key="`customer-tickets-table-${$route.params.id}`"
          />
        </b-tab>

      </b-tabs>
    </b-card>

  </div>

</template>

<script>
import {
  BCard, VBTooltip, BTab, BTabs,
} from 'bootstrap-vue'
import UserCard from '@/views/shared-components/user/UserCard.vue'
import ScootersTable from '@/views/users/ScootersTable.vue'
import RidesTable from '@/views/users/RidesTable.vue'
import TicketsTable from '@/views/users/TicketsTable.vue'
import CustomerStatistics from '@/views/users/CustomerStatistics.vue'
import ConnectPlus from '@/views/users/components/ConnectPlus.vue'
import paginator from '@/views/shared-components/general/Paginator.vue'

export default {
  components: {
    RidesTable,
    ScootersTable,
    TicketsTable,
    BCard,
    BTab,
    BTabs,
    UserCard,
    CustomerStatistics,
    ConnectPlus,
    paginator,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      isFetchingCustomerId: false,
      customerNotFound: false,
      oldFilter: '',
      items: [],
      navigationUpdated: false,
      paginator: null,
      fields: [
        { key: 'startTime', label: 'Start Time', sortable: true },
        { key: 'endTime', label: 'End Time', sortable: true },
        { key: 'scooterSerial', label: 'Scooter Serial', sortable: true },
        { key: 'user.email', label: 'Customer', sortable: true },
        { key: 'distance', label: 'Distance', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false },
      ],
    }
  },
  mounted() {
    if (this.$route.params.id.includes('@')) {
      this.fetchingCustomerId()
    }
  },
  methods: {
    fetchingCustomerId() {
      this.isFetchingCustomerId = true
      this.$http.post(this.$resources.customers.index, { search_filters: { filter: ['email', '=', this.$route.params.id] } }).then(response => {
        this.isFetchingCustomerId = false
        const rows = response?.data?.data?.rows
        if (rows?.length === 1) {
          this.$router.push(`/customers/${rows[0].id}`)
        } else {
          this.customerNotFound = true
        }
      })
    },
  },
}
</script>

<style lang="scss">

.page-title-wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
  .customer-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: #151210;
  }
}

.scooter-icon {
  background-image: url('~@/assets/images/icons/ride/scooter.png') !important;
}

.ios-icon {
  background-image: url('~@/assets/images/icons/ride/apple.png') !important;
}

.android-icon {
  background-image: url('~@/assets/images/icons/ride/android.png') !important;
}

.icon {
  background-color:#f3f3f3;
  width:35px;
  height: 35px;
  border-radius:50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px;
}

.customer-placeholder {
  .placeholder-col {
     height: 90% !important;
     padding: 0 !important;
    .card-body {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      color: #9A8781;
    }
  }
}

/** media query */
@media screen and (max-width: 991px) {
  .customer-statistics-wrapper {
    margin-top: 10px;
  }
  .customer-placeholder {
    .placeholder-col {
      display: none;
    }
  }
}

</style>
