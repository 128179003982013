<template>
  <b-row>
    <b-col>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1 mt-5"
      >
        <b-spinner label="Loading..." />
      </div>
      <new-table
        v-else
        :export-file-name="exportFileName"
        :columns="columns"
        :items="items"
        table-name="scooters-list"
        router-link="view-scooter"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  VBTooltip, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import NewTable from '@/views/new-table.vue'
import Moment from 'moment'

export default {
  components: {
    NewTable,
    BSpinner,
    BCol,
    BRow,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      moment: Moment,
      loading: false,
      oldFilter: '',
      items: [],
      exportFileName: `ScootersList_${Moment(new Date()).format('YYYY-MM-DD')}`,
      fields: [
        { key: 'type', label: 'Type', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'serial', label: 'Serial', sortable: true },
        { key: 'odometer', label: 'Odometer', sortable: true },
        { key: 'mac', label: 'MAC', sortable: true },
        { key: 'view_btn', label: 'View' },
      ],
      columns: [
        {
          label: 'Type',
          data: 'type',
        },
        {
          label: 'Emails history',
          data: 'customer_email',
          calculateCellValue(data) {
            return data.customers && data.customers.length > 0 ? data.customers.map(c => c.email).join(', ') : 'No connections'
          },
        },
        {
          label: 'Serial',
          data: 'serial',
        },
        {
          label: 'Odometer',
          data: 'odometer',
          custom_template: {
            template_name: 'scooters.odometer',
          },
        },
        {
          label: 'Mac',
          data: 'mac',
        },

      ],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    sendPin(scooter) {
      this.loading = true
      this.$http.post(this.$resources.scooters.sendPin, {
        scooter_id: scooter.id,
      }).then(() => {
        this.loading = false
        this.$notify({ type: 'danger', title: 'PinCode', text: 'Pincode sent successfully' })
      })
    },
    load(filter = null) {
      this.loading = true
      this.$http.get(this.$resources.customers.scooters.replace(':id', this.$route.params.id)).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.oldFilter = filter
          this.items = data.rows
          this.loading = false
        }
      })
    },
  },
}
</script>

<style>

</style>
